/* eslint-disable jsx-a11y/alt-text */
import React,{useState,useEffect} from "react";
import { Box, Grid } from "@mui/material";
import "./common.css";
import { EmailSvg } from "../assets/assests";
import Loader from "./Loader";



export default function Services() {
  const [loading, setloading] = useState(true);
  const [NumberCaurasel, setNumberCaurasel] = useState(3);
  useEffect(() => {
    document.title = "Poly - Home";
    console.log("@@@ ineer width", window.innerWidth <= 420);
    if (window.innerWidth <= 420) {
      setNumberCaurasel(1);
    }
    setloading(false);
  }, []);

  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/")) {
      window.scrollTo(850, 0);
    }
  };
 

const Services = () => {
  return (
    <div className="servicecontsiner">
      <Box className="servicesmain">
        <h3 className="services-h3">OUR SERVICES</h3>
        <h1 className="provides-h1">Services We Provide</h1>

        <Box className="servicesmainBox">
          <Grid container className="servicesbox">
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              className="servicesbox"
            >
              <div className="servicesbox1">
                <img
                  src={require("../assets/img/team/loudspeaker.svg").default}
                  className="smallboximg"
                />
                <div>
                  <h3 className="services_head">Mobile App Development</h3>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              className="servicesbox"
            >
              <div className="servicesbox2">
                <img
                  src={require("../assets/img/team/security manager.svg").default}
                  className="smallboximg"
                />
                <div>
                  <h3 className="services_head" >UI/UX Design</h3>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              className="servicesbox"
            >
              <div className="servicesbox3">
                <img
                  src={require("../assets/img/team/Emailmarket.svg").default}
                  className="smallboximg"
                />
                <div>
                  <h3  className="services_head">Web Devlopment</h3>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              className="servicesbox"
            >
              <div className="servicesbox4">
                <img
                  src={require("../assets/img/team/userAdministration.svg").default}
                  className="smallboximg"
                />
                <div>
                  <h3 className="services_head">QA & Testing</h3>
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              md={3}
              lg={3}
              xl={3}
              className="servicesbox"
            >
              <div className="servicesbox5">
                <img
                  src={require("../assets/img/team/Team account.svg").default}
                  className="smallboximg"
                />
                <div>
                  <h3  className="services_head">Dedicated  Teams</h3>
                </div>
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </div>
  );
};

return(
  <div>
  {loading ? (
    <div className="spinner">
      <Loader />{" "}
    </div>
  ) : (
    <>
  {Services()}
  </>
  )}
</div>
);
}