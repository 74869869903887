/* eslint-disable jsx-a11y/alt-text */
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import {
  avatar1,
  avatar2,
  Avatar3,
  Avatar4,
  growyourbusinessbig,
} from "../assets/assests";

export default function Growyourbusiness() {
  const Growyourbusiness = () => {
    return (
      <div>
        <div className="AvatarContainer">
          <p className="services-h3">OUR TEAM</p>
          <h1 className="provides-Avatar">Core Team Members</h1>
          <Box className="AvatarContainer">
            <Grid container justifyContent="space-evenly" className="Avaterdiv">
              <Grid className="avatar-img-info">
                <img src={avatar1}  className="avatar_class"/>
                <p className="avatarinfo">Rohit</p>
                <p className="avatarinfo2"> Mobile Developer</p>
              </Grid>
              <Grid className="avatar-img-info">
                <img src={avatar2}  className="avatar_class" />
                <p className="avatarinfo">Gaurav</p>
                <p className="avatarinfo2">Web Developer</p>
              </Grid>
              <Grid className="avatar-img-info">
                <img src={Avatar3}   className="avatar_class"/>
                <p className="avatarinfo">Vivek</p>
                <p className="avatarinfo2"> QA</p>
              </Grid>
              <Grid className="avatar-img-info">
                <img src={Avatar4}  className="avatar_class" />
                <p className="avatarinfo">Mohan</p>
                <p className="avatarinfo2"> Product Designer</p>
              </Grid>
            </Grid>
          </Box>
        </div>
      </div>
    );
  };

  return <>{Growyourbusiness()}</>;
}
