//@ts-nocheck
import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./Components/Header.web";
/** Routes List start */

import Home from "./Components/Home.web";
import Services from "./Components/Services.web";
import About from "./Components/About.web";
import SlideCarousel from "./Components/SlideCarousel.web";
import Growyourbusiness from "./Components/Growyourbusiness.web";
import Footer from "./Components/Footer.web";

function WebRoutes() {
  // render() {
  return (
    <Router>
      <Suspense>
        <Header />
        <Switch>
          <Route path="/" exact render={(props) => <Home {...props} />} />
          <Route
            path="/Services"
            exact
            render={(props) => <Services {...props} />}
          />
          <Route path="/About" exact render={(props) => <About {...props} />} />
          <Route
            path="/SlideCarousel"
            exact
            render={(props) => <SlideCarousel {...props} />}
          />

          <Route
            path="/Growyourbusiness"
            exact
            render={(props) => <Growyourbusiness {...props} />}
          />
        </Switch>
        <Footer />
      </Suspense>
    </Router>
  );
  // }
}

//@ts-ignore
export default WebRoutes;
