import React, { Component } from 'react'
import {POLYLOGO,POLYGIF} from "../assets/assests";
import "../App.css"

export class Loader extends Component {
  render() {
    return (
        <div className='spinner'>
          <div className='div_main_loader'>
          <img src={POLYGIF} alt="LoaderGif" className='loader_main_img'/>
          <img src={POLYLOGO} alt="" className="loader_Set"  />
          </div>
        </div>
    )
  }
}

export default Loader