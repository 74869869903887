/* eslint-disable jsx-a11y/alt-text */
import { Box } from "@mui/material";
import React,{useState, useEffect} from "react";
import "./common.css";
import {
  Securitymanagement1,
  Securitymanagement2,
  Securitymanagement3,
} from "../assets/assests";
import Loader from "./Loader";
import Footer from "./Footer.web";



export default function Home() {
  
  const CaseStudies = () => {
  return (
    <div>
      <div className="Securitymanagementcontainer">
        <Box>
          <div className="Securitymanagementbox">
            <div className="card-img-info">
              <img src={Securitymanagement1} />
              <p className="securityinfo">Food-App</p>
              <p className="avatarinfo2"> Domain</p>
            </div>
            <div className="card-img-info">
              <img src={Securitymanagement2} />
              <p className="securityinfo">E-Commerce</p>
              <p className="avatarinfo2">Domain</p>
            </div>
            <div className="card-img-info">
              <img src={Securitymanagement3} />
              <p className="securityinfo">Medical</p>
              <p className="avatarinfo2">Domain</p>
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
};

return(
  <>
  {CaseStudies()}
 </>
)
}
