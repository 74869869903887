/* eslint-disable jsx-a11y/alt-text */
import Carousel from "react-elastic-carousel";
// import { Carousel } from "antd";
import React, { Component } from "react";
import "./common.css";

import { people, comaimg,boy2 ,boy3,boy4,boy5,boy6,boy7,boy8,boy9,boy10,boy11} from "../assets/assests";

export default class SlideCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [
        { playlist: "Playlist-1", image: boy2, name:"Vinay Kumar", text: "Web Developer" , description:"I went to Thrive with a basic “feel” I wanted in a website. They helped flush out the visual layout and content.  Thanks again!"},
        { playlist: "Playlist-2",image: boy3,name:"Rohan", text: "Mobile Developer" , description:"The attention to detail with Thrive’s professional staff is incredible."},
        { playlist: "Playlist-3", image: boy4,name:"Rohit", text: "QA", description:"Our site is beautiful! It is easy to navigate. The support has been remarkable for WordPress; " },
        { playlist: "Playlist-4",image: boy5,name:"John", text: "Product Designer",description:"Thrive is a great company to work with, I use them for a lot more than just building a website. It’s almost as if they are an employee. " },
        { playlist: "Playlist-5", image: boy6,name:"Ram", text: "Full Stack Developer" ,description:"Thrive’s design and web work has provided a vast improvement for our site visits, service inquiries and ease of maintaining"},
        { playlist: "Playlist-6", image: boy7,name:"Shyam", text: "UX Designer",description:"Thrive has done an excellent job on our website, using different marketing techniques." },
        { playlist: "Playlist-7", image: boy8,name:"Mohan", text: "Backend Developer" ,description:"Thank you so much for the report!  I think our ad campaign is performing extremely well and our calls and emails are flooding in.  . "},
        { playlist: "Playlist-8", image: boy2,name:"Kunal" ,text: "Data Scientist",description:"Thrive provides great support and one-on-one attention to their clients. " },
        { playlist: "Playlist-9", image: boy10,name:"Shyam", text: "Product manager",description:"This was the first time I developed a web site so I couldn’t have asked for a better or easier group to work with. " },
        { playlist: "Playlist-10", image: boy11,name:"Rahul", text: "Tester",description:"Thrive is a great company to work with as they are always responsive and willing to do what it takes to get the job done right and in a timely manner." },
      ],
    };
  }

  render() {
    let carousel = React.createRef();
    const breakPoints = [
      { width: 1, itemsToShow: 1 },
      { width: 550, itemsToShow: 1, itemsToScroll: 1},
      { width: 768, itemsToShow: 1 },
      { width: 850, itemsToShow: 2 },
      { width: 1150, itemsToShow: 2, itemsToScroll: 2 },
      { width: 1200, itemsToShow: 3 },
      { width: 1450, itemsToShow: 3 },
      { width: 1750, itemsToShow: 3 },
     
    ];

    return (
      <>
        <div className="VideoPlaylist-box">
          <div className="d">
            <div className="Box-heading">
              <div className="headingBox">
                <p className="heading1">Customer Reveiew</p>
                <p className="heading2">Our Recent Works</p>
              </div>
            </div>
            <div className="playlist-item">
              <Carousel
                breakPoints={breakPoints}
                isRTL={false}
                enableAutoPlay
                //@ts-ignore
                ref={carousel}
                showArrows={true}
                itemsToShow={3}
                autoPlaySpeed={2000}
             
              >
                {this.state.data?.map((item) => {
                  return (
                    <>
                      <div className="cardBox">
                        <div className="cardBoxFlex">
                          <div className="cardBoxFlex">
                            <img src={item.image} width="80px" height="80px" style={{borderRadius: "50px"}} />
                            <div className="card_name">
                              <p className="C_name">{item.name}</p>
                              <p className="C_designation">{item.text}</p>
                            </div>
                          </div>
                          <div>
                            <img src={comaimg} />
                          </div>
                        </div>
                        <div>
                          <p className="C_review">
                          {item.description}
                          </p>
                        </div>
                      </div>
                    </>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}
