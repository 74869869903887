// Customizable Area Start
export const POLYLOGO = require("../assets/img/logo/PolyLogo.png");
export const HERO2 = require("../assets/img/hero/hero2.png");
export const POLYGIF = require("../assets/img/polyGif.gif");
export const POLY1 = require("../assets/img//hero/poly1.png");
export const POLY2 = require("../assets/img/hero/poly2.png");
export const POLY3 = require("../assets/img/hero/poly3.png");
export const POLY4 = require("../assets/img/hero/poly4.png");
export const POLY5 = require("../assets/img/hero/poly5.png");
export const POLY6 = require("../assets/img/hero/poly6.png");
export const SIDECAROUSEL = require("../assets/img/hero/side-carousel.png");
export const POLYTHIRD = require("../assets/img/hero/poly-third.png");



//======> akhilesh code ========>

export const EmailSvg = require("../assets/img/team/Email _big_svg.png");
export const Emailmarket = require("../assets/img/team/Emailmarket.svg");
export const loudspeaker = require("../assets/img/team/loudspeaker.svg");
export const securityManager = require("../assets/img/team/security manager.svg");
export const avatar1 = require("../assets/img/team/avatar1.jpg");
export const avatar2 = require("../assets/img/team/avatar2.jpg");
export const Avatar3 = require("../assets/img/team/Avatar3.jpg");
export const Avatar4 = require("../assets/img/team/Avatar4.jpg");
export const Securitymanagement1 = require("../assets/img/team/Securitymanagement1.png");
export const Securitymanagement2 = require("../assets/img/team/securitymanagement2.png");
export const Securitymanagement3 = require("../assets/img/team/securitymanagement3.png");
export const growyourbusinessbig = require("../assets/img/team/growyourbusinessbig.png");
export const people = require("../assets/img/team/testimonial_people.jpg");
export const comaimg = require("../assets/img/team/comaimg.png");
// export const boy2 = require("../assets/img/team/Boy2.jpg");
export const boy3 = require("../assets/img/team/Boy3.png");
export const boy4 = require("../assets/img/team/Boy4.png");
export const boy5 = require("../assets/img/team/Boy5.png");
export const boy6 = require("../assets/img/team/Boy6.png");
export const boy7 = require("../assets/img/team/Boy7.png");
export const boy8 = require("../assets/img/team/Boy8.png");
export const boy9 = require("../assets/img/team/Boy9.png");
export const boy10 = require("../assets/img/team/Boy10.png");
export const boy11 = require("../assets/img/team/Boy1.png");
export const boy2 = require("../assets/img/team/Boy2.png");







// ========>sumit =======++> 





// Customizable Area End