import React,{useEffect, useState} from 'react';
import './App.css';
import WebRoutes from './WebRoutes';
import "antd/dist/antd.min.css"
import { HashLoader } from 'react-spinners';
import Loader from './Components/Loader';

function App() {
const[loading, setloading] = useState(true);
  useEffect(()=>{
    // setloading(true)
    setTimeout(() => {
      setloading(false)
    }, 1000);
  },[])

  return (
    <div className="App">
    {loading ?
    <Loader/>
    :
   <WebRoutes/>
    } 
  </div>
);
}


export default App;
