import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";

// @ts-ignore
import {
  HERO2,
  POLY1,
  POLY2,
  POLY3,
  POLY4,
  POLY5,
  POLY6,
  SIDECAROUSEL,
  POLYTHIRD,
} from "../assets/assests";

//@ts-ignore
import { Link } from "react-router-dom";

//@ts-ignore
import aos from "aos";
import "aos/dist/aos.css";
import { FaPlay } from "react-icons/fa";
import Loader from "./Loader";
import Services from "./Services.web";
import About from "./About.web";
import SlideCarousel from "./SlideCarousel.web";
import Growyourbusiness from "./Growyourbusiness.web";
import Footer from "./Footer.web";

export default function Home() {
  const Data = [
    { image: POLY1 },
    { image: POLY2 },
    { image: POLY3 },
    { image: POLY4 },
    { image: POLY5 },
    { image: POLY6 },
  ];
  const [loading, setloading] = useState(true);
  const [NumberCaurasel, setNumberCaurasel] = useState(3);

  useEffect(() => {
    // window.scroll({ top: 1, left: 0, behavior: "smooth" });
    document.title = "Poly - Home";
    console.log("@@@ ineer width", window.innerWidth <= 420);
    if (window.innerWidth <= 420) {
      setNumberCaurasel(1);
    }

    {
      redirectToOrderHistory();
    }
    setloading(false);
  }, [1]);

  const redirectToOrderHistory = () => {
    if (window.location.href.includes("/")) {
      window.scrollTo(850, 0);
    }
  };

  const renderHeaderSection = () => {
    return (
      <>
        <section className="header_down">
          <Row className="container home_hero_main">
            <Col lg={12} md={12} xl={12} sm={24} xs={24}>
              <div className="Home_content_left">
                <div>
                  <span className="sub_title">POLYMORPHIC</span>
                  <h3 className="heading-home">
                    Excellent IT services for your success
                  </h3>
                  <p className="home-para">
                    PolyMorPhic is a trusted and highly accredited organisation
                    that provides IT consultancy services and technology
                    solutions.
                  </p>
                  <div className="hero_contact_btn">
                    <div className="buttonDiv">
                      <Link to="/footer">
                        <button className="EmailMarketingButton">
                          Contact us
                        </button>
                      </Link>
                    </div>
                    <Link to="/footer">
                    <a className="play_btn" href="http://">
                      <FaPlay />
                    </a>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>

            <Col
              lg={12}
              md={12}
              xl={12}
              sm={24}
              xs={24}
              className="hero_main_image"
            >
              <img className="hero_image" src={HERO2} alt="HeroImage" />
            </Col>
          </Row>
        </section>
      </>
    );
  };

  const renderThirdHomeSection = () => {
    return (
      <>
        <section className="third_home">
          <Row className="container home_hero_main ">
            <Col
              lg={12}
              md={24}
              xl={12}
              sm={24}
              xs={24}
              className="hero_main_image"
            >
              <img className="poly_third_img" src={POLYTHIRD} alt="HeroImage" />
            </Col>
            <Col lg={12} md={24} xl={12} sm={24} xs={24}>
              <div className="Home_content_right_third">
                <div style={{ width: "100%" }}>
                  <span className="sub_title_third">POLYMORPHIC SERVICE</span>
                  <h2>
                    Thinks We Are Doing
                    <br />
                  </h2>

                  <p>
                    At PolyMorPhic, we've always tried to do things a little
                    differently. We believe that a company can grow with a clear
                    conscience and succeed with a soul.{" "}
                  </p>
                  <p>
                    By injected humour, or ranma omised words which don't look
                    sing hidden in the middle of text.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </>
    );
  };

  return (
    <>
      {renderHeaderSection()}
      {renderThirdHomeSection()}
      <Services />
      <Growyourbusiness />
      <About />
      <SlideCarousel />
    </>

);
}
