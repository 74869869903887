import MenuIcon from "@mui/icons-material/Menu";

import React, { useEffect, useRef, useState } from "react";
import { POLYLOGO } from "../assets/assests";
// @ts-ignore
import { Link as Links } from "react-scroll";
// @ts-ignore
import { Link } from "react-router-dom";
function Header() {
  const [sticky, setSticky] = useState({ isSticky: false, offset: 0 });
  const headerRef = useRef(null);

 
const handleScroll = (elTopOffset: any, elHeight: any) => {
  if (window.pageYOffset > (elTopOffset + elHeight)) {
    setSticky({ isSticky: true, offset: elHeight });
  } else {
    setSticky({ isSticky: false, offset: 0 });
  }
};

useEffect(() => {
  // @ts-ignore 
  var header = headerRef.current.getBoundingClientRect();
  const handleScrollEvent = () => {
    handleScroll(header.top, header.height)
  }
 
  window.addEventListener('scroll', handleScrollEvent);
 
  return () => {
    window.removeEventListener('scroll', handleScrollEvent);
  };
}, []);

  return (
    <nav
      id="sticky-header"
      className={`navbar${sticky.isSticky ? ' navbar-expand-lg nav_main sticky' : ' navbar-expand-lg nav_main'}`}
    ref={headerRef}>
    
      <div className="container header_main">
        <Link to="/">
          <a className="navbar-brand">
            {" "}
            <img src={POLYLOGO} className="logo"></img>{" "}
          </a>
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <MenuIcon />
        </button>
        <div className="collapse navbar-collapse"  id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 nav_class  nav_main_ul"  >
          
          <li className= "nav-item">
              <Link
                to="/"
                className={
                  window.location.pathname === "/"
                    ? "nav-link_underline"
                    : "nav-link nav_li_new "
                }
                aria-current="page"
              >
                Home
              </Link>
            </li>
            <Links to="#About">
            <li className= "nav-item">
                <Link
                  className={
                    window.location.pathname === "/About"
                      ? "nav-link_underline"
                      : "nav-link"
                  }
                  aria-current="page"
                  to="/About"
                >

                  
                  About
                </Link>
              </li>
            </Links>
            <Links to="#Services">
            <li className= "nav-item">
                <Link
                  className={
                    window.location.pathname === "/services"
                      ? "nav-link_underline"
                      : "nav-link"
                  }
                  aria-current="page"
                  to="/services"
                >
                  Services
                </Link>
              </li>
            </Links>
            <li className= "nav-item">
              <Link
                to="/Footer"
                className={
                  window.location.pathname === "/Footer"
                    ? "nav-link_underline"
                    : "nav-link"
                }
              >
                Contact
              </Link>
            </li>
          </ul>
          {/* <div> */}
          {/* <div className="search d-lg-block open">
              <form action="#" method="get" className="search-form">
                <div className="input-group">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search..."
                  />
                  <button className="search_arrow" type="submit">
                    <AiOutlineArrowRight />
                  </button>
                </div>
              </form>
            </div> */}
          {/* </div> */}
        </div>
      </div>
    </nav>
  );
}

export default Header;
