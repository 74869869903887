import React, { Component } from "react";
import "../assets/CSS/Footer.css";
import {
  Instagram,
  Facebook,
  Behance,
  Dribbble,
  HeartFill,
  Linkedin
} from "react-bootstrap-icons";
import { POLYLOGO } from "../assets/assests";

export class Footer extends Component {
  render() {
    return (
      <div>
        <section
          className="footer_aria home_2 wow fadeInUp"
          data-wow-delay="0.1s"
          style={{
            visibility: "visible",
            animationDelay: "0.1s",
            animationName: "fadeInUp",
          }}
        >
          <div className="container custom_container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="site_info">
                  <img src={POLYLOGO} alt="" className="footer_logo" />
                  <div className="content">
                    <p>
                      Polymorphic have much planned for the future, working with
                      great clients and continued software development. If you'd
                      like to join our team, then we'd also love to hear from
                      you.
                    </p>
                  </div>
                  {/* <div className="social_icons">
                    <a href="https://www.instagram.com/">
                      <Instagram className="s_icons" />
                    </a>
                    <a href="https://www.facebook.com/">
                      <Facebook className="s_icons" />
                    </a>
                    <a href="https://www.Linkedin.com/">
                      <Linkedin className="s_icons" />
                    </a>
                  
                  </div> */}
                </div>
              </div>
              <div className="col-lg-2 col-md-6">
                <div className="site_info">
                  <h4 style={{ color: "#fff" }}>About Us</h4>
                  <ul className="list list-unstyled">
                    <li>
                      <a href="email-marketing.html">Email Marketing</a>
                    </li>
                    <li>
                      <a href="service.html">Services</a>
                    </li>
                    <li>
                      <a href="lead-generation.html">Lead Generation</a>
                    </li>
                    <li>
                      <a href="team.html">Our Team</a>
                    </li>
                    <li>
                      <a href="social-media-marketing.html">Social Media </a>
                    </li>
                    <li>
                      <a href="contact.html">Contact</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="site_info">
                  <h4 style={{ color: "#fff" }}>Workflows</h4>
                  <ul className="list list-unstyled">
                    <li>B-8 3rd floor Sector-2 Noida UP 201301</li>
                    <li>
                      <a href="https://www.polymorphic.in/">Website: https://www.polymorphic.in</a>
                    </li>
                    <li>
                      <a href="mailto:seofy@mail.com">
                        Email: contact@polymorphic.in
                      </a>
                    </li>
                    <li>
                      <a href="tel:+4733378901">Phone: +917753943386</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="site_info">
                  <h4 style={{ color: "#fff" }}>Newsletter</h4>
                  <div className="footer_subscribe">
                    <div>
                      <input
                        type="email"
                        className="form-control input"
                        placeholder="Enter your email"
                      />
                      <input
                        type="button"
                        value="Subscribe"
                        className="min_btn"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                    <div className="footer_bottom text-center">
                        <p>Copyright ©2022 All rights reserved. <span style={{ fontWeight:"500" ,color:"#fff"}}></span> </p>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Footer;
